<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Wholesale</h4>
        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-sm-2">
        
        </div>
        <div class="col-sm-1">
           
        </div>
      </div>
    </header>
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Actions</h2>
                  </div>
                </div>
                
                
                <div class="row">
                  <div class="col-sm-4">
                    <label>Update Prices</label>
                  </div>
                  <div class="col-sm-8">
                    <form @submit.prevent="processPrices">
                      <button type="submit" name="submit">Process</button>
                    </form>
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Update Stock</label>
                  </div>
                  <div class="col-sm-8">
                    <form @submit.prevent="processStock">
                      <button type="submit" name="submit">Process</button>
                    </form>
                  </div>
                </div>
                
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      popular: []
    }
  },
  
  created () {
    
  },
  methods: {
    processPrices () {
      axios
        .get(`/wholesaleplatform/actions/update-prices`, this.topbarcontent)
        .then(response => (
          this.$toast.success("Prices updated", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
        this.$toast.warning("Prices are being updated", {});  
    },
    processStock () {
      axios
        .get(`/wholesaleplatform/actions/update-stock`, this.topbarcontent)
        .then(response => (
          this.$toast.success("Stock updated", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
        this.$toast.warning("Stock is being updated", {});  
    }
  }
}
</script>
