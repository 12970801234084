<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div v-if="isLoading" class="commerceLoading">
      <div class="basco-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard">
                  <div class="row">
                    <div class="col-sm-4">
                     <h4 class="reference">{{ order.order_number }}</h4>
                    </div>
                    <div class="col-sm-8" v-if="order.status === 'draft'">
                      <div align="right">
                        <router-link class="btnGreenLineSmall" :to="{name: 'OrdersEdit', params: { id: $route.params.id }}">Edit Order</router-link> <a style="margin-left:10px;" class="btnRedLineSmall" @click.prevent="deleteOrder()">Delete</a>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <form @submit.prevent="updateOrder">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="itemsGroup">
                          <div class="itemsList">
                            <dt class="item">Contact</dt>
                            <dd class="item">
                                {{ contacts.first_name }} {{ contacts.last_name }}
                            </dd>
                          </div>  
                          <div class="itemsList">
                            <dt class="item">Billing</dt>
                            <dd class="item">
                              {{ billing.address1 }}, {{ billing.address2 }}, {{ billing.city }}, {{ billing.county }}, {{ billing.postcode }}
                            </dd>
                          </div>
                          <div class="itemsList">
                            <dt class="item">Shipping</dt>
                            <dd class="item">
                              {{ shipping.address1 }}, {{ shipping.address2 }}, {{ shipping.city }}, {{ shipping.county }}, {{ shipping.postcode }}
                            </dd>
                          </div>
                        </div>
                      </div>
                        <div class="col-sm-4">
                          <div class="itemsGroup">
                            <div class="itemsList">
                              <dt class="item">Issue Date</dt>
                              <dd class="item">
                                {{ order.issued_at | formatDate }}
                              </dd>
                            </div>
                            <div class="itemsList">
                              <dt class="item">Delivery Date</dt>
                              <dd class="item">
                                {{ order.ship_at | formatDate }}
                              </dd>
                            </div>
                            <div class="itemsList">
                              <dt class="item">Reference</dt>
                              <dd class="item">{{ order.customer_reference }}</dd>
                            </div>
                          </div>
                        </div>
                      <div class="col-sm-4">
                        <div class="itemsGroup">
                          <div class="itemsList">
                            <dt class="item">Currency</dt>
                            <dd class="item">{{ order.currency }}</dd>
                          </div>
                          <div class="itemsList">
                            <dt class="item">Delivery</dt>
                            <dd class="item">{{ order.delivery_method }}</dd>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <hr />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <table class="orderItem">
                          <thead>
                            <tr>
                              <th>Item Name</th>
                              <th style="width: 9%;">Quantity</th>
                              <!--<th style="width: 9%;">Available</th>-->
                              <th style="width: 16%;">Price (GBP)</th>
                              <th style="width: 80px;">Discount</th>
                              <th style="width: 80px;">VAT</th>
                              <th style="width: 12%;">Total (GBP)</th>
                              <th style="width: 30px;"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- Commerce -->
                            <tr v-for="orderitem in orderitems" :key="orderitem.id">
                              <td>
                                <img :src="orderitem.image" width="40" style="float: left; margin-right: 10px;" />
                                <span :id="'product-' + orderitem.id" style="cursor: pointer;">
                                {{ orderitem.name }}<br />
                                <span v-if="orderitem.sku">{{ orderitem.sku }}<br /></span>
                                </span>
                                <b-tooltip :target="'product-' + orderitem.id" placement="top">
                                  <table width="480">
                                    <tr>
                                      <td width="40%" style="vertical-align: top;">
                                        <table width="100%">
                                          <tr>
                                            <td style="text-align: left; color: #c2bfb3;" width="70%">Stock on hand: </td>
                                            <td style="text-align: left;">{{ orderitem.stock_on_hand }}</td>
                                          </tr>
                                          <tr>
                                            <td style="text-align: left; color: #c2bfb3;">Allocated Qty: </td>
                                            <td style="text-align: left;">{{ orderitem.allocated }}</td>
                                          </tr>
                                          <tr>
                                            <td style="text-align: left; color: #c2bfb3;">Available Qty: </td>
                                            <td style="text-align: left;">{{ orderitem.available }}</td>
                                          </tr>
                                          <tr>
                                            <td style="text-align: left; color: #c2bfb3;">On Purchase: </td>
                                            <td style="text-align: left;">{{ orderitem.on_purchase }}</td>
                                          </tr>

                                        </table>
                                      </td>
                                      <td width="60%" style="text-align: left; color: #c2bfb3; line-height: 1.2; vertical-align: top;">
                                        {{ orderitem.notes }}
                                      </td>
                                    </tr>
                                  </table>
                                </b-tooltip>
                                <span v-if="orderitem.label" style="font-size:11px; background: var(--bascoYellow); padding: 3px 5px; border-radius: 3px;">{{ orderitem.label }}<br /></span>
                              </td>
                              <td>{{ orderitem.quantity }}</td>
                              <!--<td></td>-->
                              <td>&pound;{{ orderitem.unit_price.toFixed(2) }}</td>
                              <td>{{ orderitem.discount }}%</td>
                              <td>
                                {{ orderitem.taxrate }}%
                              </td>
                              <td>&pound;{{ orderitem.total_price.toFixed(2) }}</td>
                              <td></td>
                            </tr>
                            <!-- Unleashed -->
                            <!--<tr v-for="orderline in unleashed.SalesOrderLines" :key="orderline.Guid">
                              <td>
                                {{ orderline.Product.ProductDescription }}<br />
                                <span v-if="orderline.Product.ProductCode">{{ orderline.Product.ProductCode }}<br /></span>
                                
                              </td>
                              <td>{{ orderline.OrderQuantity }}</td>
                              <td></td>
                              <td>&pound;{{ orderline.UnitPrice.toFixed(2) }}</td>
                              <td>{{ orderline.DiscountRate }}%</td>
                              <td>
                                {{ orderline.LineTax.toFixed(2) }}
                              </td>
                              <td>&pound;{{ orderline.LineTotal.toFixed(2) }}</td>
                              <td></td>
                            </tr>-->
                          </tbody>
                          <tfoot>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <textarea v-model="order.notes" rows="5" placeholder="Order notes"></textarea>
                      </div>
                      <div class="col-sm-1"></div>
                      <div class="col-sm-5">
                        <div class="row">
                          <div class="col-sm-6">
                            Sub-Total
                          </div>
                          <div class="col-sm-6">
                            &pound;{{ order.order_subtotal.toFixed(2) }}
                          </div>
                        </div>
                        <!--<div class="row">
                          <div class="col-sm-6">
                            Delivery
                          </div>
                          <div class="col-sm-6">
                            &pound;{{ order.order_delivery }}
                          </div>
                        </div>-->
                        <div class="row">
                          <div class="col-sm-6">
                            VAT
                          </div>
                          <div class="col-sm-6">
                            &pound;{{ order.order_vat.toFixed(2) }}
                          </div>
                        </div>
                        <div class="row" style="margin-top:10px;">
                          <div class="col-sm-6">
                            <strong>Total</strong>
                          </div>
                          <div class="col-sm-6">
                            <strong>&pound;{{ order.order_total.toFixed(2) }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--<div class="row">
                      <div class="col-sm-12">
                        <hr />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <label style="text-align: left;">Tags</label>
                      </div>
                      <div class="col-sm-3">
                        <input type="text" v-model="order.tags">
                      </div>
                      <div class="col-sm-8">
                        <div align="right">
                          
                        </div>
                      </div>
                    </div>-->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard greenTop">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6>Order</h6>
                    </div>
                  </div>
                  <div class="row" v-if="order.status === 'draft'">
                    <div class="col-sm-12">
                      <a class="btnOrderAction" @click.prevent="finaliseOrder()">Finalise <font-awesome-icon icon="angle-double-right" /></a>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-sm-6">
                      Status:
                    </div>
                    <div class="col-sm-6">
                      {{ order.status }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard greenTop">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6>Invoices</h6>
                    </div>
                  </div>
                  <div class="row" v-if="order.status === 'active'">
                    <div class="col-sm-12">
                      <a class="btnOrderAction" @click.prevent="invoiceOrder()">Invoice order <font-awesome-icon icon="angle-double-right" /></a>
                    </div>
                  </div>
                  <div class="row" v-if="order.status === 'finalised' || order.status === 'fullfilled'">
                    <div class="col-sm-6">
                      Invoice Date:
                    </div>
                    <div class="col-sm-6">
                    </div>
                  </div>
                  <div class="row" v-if="order.status === 'finalised' || order.status === 'fullfilled'">
                    <div class="col-sm-6">
                      Invoice Number:
                    </div>
                    <div class="col-sm-6">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard greenTop">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6>Payments</h6>
                    </div>
                  </div>
                  <div class="row" v-if="order.payment_status === 'yes' && order.payment_status === ''">
                    <div class="col-sm-6">
                      Order Paid
                    </div>
                  </div>
                  <div class="row" v-else-if="order.payment_status === 'credit'">
                    <div class="col-sm-6">
                      Customer on credit
                    </div>
                  </div>
                  <div class="row" v-else>
                    <!--<div class="col-sm-6">
                      <div class="commerceSelect">
                        <select v-model="paymentaction.method">
                          <option>PDQ</option>
                          <option>BACS</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <a class="btnOrderAction" @click.prevent="payOrder()">Pay <font-awesome-icon icon="angle-double-right" /></a>
                    </div>-->
                    <!--
                    <div class="col-sm-12">
                      <a class="btnOrderAction" @click.prevent="payOrderStored()">Pay by stored credit / debit card <font-awesome-icon icon="angle-double-right" /></a>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard greenTop">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6>Shipments</h6>
                    </div>
                  </div>
                  <div v-if="order.invoice_status === 'invoiced'">
                    <div class="row" v-if="order.fulfillment_status === 'shipped'">
                      <div class="col-sm-6">
                        Order Sent:
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-sm-12">
                        <a class="btnOrderAction" @click.prevent="fulfillOrder()">Fulfill Order <font-awesome-icon icon="angle-double-right" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard greenTop">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6>History</h6>
                    </div>
                  </div>
                  <div v-for="historyItem in history" :key="historyItem.id" style="margin-top: 10px;">
                    <div class="row historyItem">
                      <div class="col-sm-12">
                        <div class="cardContainer">
                          {{ historyItem.description }}
                        </div>
                        <span>{{ historyItem.created_at | formatDateTime }} by {{ historyItem.user }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  components: { },
  data () {
    return {
      order: [],
      orderitems: [],
      unleashed: [],
      isLoading: true,
      paymentaction: [],
      history: [],
      contacts: [],
      billing: [],
      shipping: []
    }
  },
  watch: {
    productInput (after, before) {
      if (after === null || before === null) {
        this.DoNothing()
      } else {
        this.searchProduct()
      }
    }
  },
  created () {
    axios
      .get(`/orders/${this.$route.params.id}`)
      .then((res) => {
        this.order = res.data.order
        this.unleashed = res.data.unleashed
        this.isLoading = false
        console.log(res.data)
        this.orderitems = res.data.order_items

        axios
          .get(`/contacts/${this.order.contact_id}`)
          .then(response => {
            this.contacts = response.data
          })

        axios
          .get(`/addresses/${this.order.shipping_address_id}`)
          .then(response => {
            this.shipping = response.data
          })

        axios
          .get(`/addresses/${this.order.billing_address_id}`)
          .then(response => {
            this.billing = response.data
          })

      })
    axios
      .get(`/history/order/${this.$route.params.id}`)
      .then((res) => {
        this.history = res.data
        //console.log(res.data)
        //this.orderitems = res.data.order_items
      })
    /*axios
      .get(`/companies/${this.order.company_id}/contacts`)
      .then(response => {
        this.contacts = response.data
      })
    axios
      .get(`/companies/${this.order.company_id}/addresses`)
      .then(response => {
        this.addresses = response.data
      })*/
    
  },
  methods: {
    refreshOrder () {
      axios
        .get(`/orders/${this.$route.params.id}`)
        .then((res) => {
          this.order = res.data.order
          this.unleashed = res.data.unleashed
          this.isLoading = false
        })
      /*axios
        .get(`/orders/${this.$route.params.id}/orderitems`)
        .then((res) => {
          this.orderitems = res.data
        })*/
    },
    deleteOrder () {
      axios
        .get(`/orders/${this.$route.params.id}/actions/delete`, this.order)
        .then(response => (
          this.$toast.success("Order deleted", {}),
          this.refreshOrder()
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    finaliseOrder () {
      this.$toast.warning("Order processing, please wait one moment", {}),
      axios
        .get(`/orders/${this.$route.params.id}/actions/finalise`, this.order)
        .then(response => (
          this.$toast.success("Order processed", {}),
          this.refreshOrder()
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    payOrder () {
      axios
        .post(`/orders/${this.$route.params.id}/actions/pay`, this.order)
        .then(response => (
          this.refreshOrder(),
          this.$toast.success("Payment processed", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    payOrderStored () {
      axios
        .post(`/orders/${this.$route.params.id}/actions/pay`, this.order)
        .then(response => (
          this.refreshOrder(),
          this.$toast.success("Payment processed", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    fulfillOrder () {
      axios
        .post(`/orders/${this.$route.params.id}/actions/fulfill`, this.order)
        .then(response => (
          this.refreshOrder()
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
  }
}
</script>
